import { Injectable } from "@angular/core";
import { IdentityGenerator, LogType, SessionStorageProvider } from "projects/den-core";
import { ClientGeoParams } from "projects/den-core/page-builder";
import { environment } from "projects/den-web/src/environments/environment";
import { Subject } from "rxjs";

export class LogInfo {
    page: string;   // page-name | fluent-service |
    group: string;  // page-builder | domain-modeller | fluent-service |
    message: string;
    loggedIn: string;
    logType: LogType;
}
export enum CategoryEnum{
    PageBuilder = 'Page Builder',
    DomainModelling = 'Domain Modelling',
    FluentService = 'fluent-service'

}

export class LogData {
  constructor(public instanceId: string,public pageName: string, public  dsName?: string, public pageNumber?:number, public pageSize?:number){

  }
}

// credits: https://try.sentry-demo.com/api/0/projects/able-heron/nextjs/events/a76be2e1b0fd4c7898dacd14386be68d/json/
export class DevumEventHandler {
    eventId = IdentityGenerator.guid();
    version: string;
    datetime = new Date().toISOString();
    tags: string[]; // [ ['browser', 'chrome'], ['browser-version', '1.0.0'], ['device', 'mobile'], ['environment', 'production'], ['user', 'user-id'] ]
    isReported = false;
    clickupUrl: string;
    contexts: {
        os?: {
            name: string;
            version: string;
        },
        browser: {
            name: string;
            version: string;
        },
        trace?: {
            trace_id: string;
            status: string;
            type: string; // trace | error | warning | info
        }
    };
    environment: string;
    breadcrumbs: {
        type: string; // navigation | http | error | info | warning
        level: string; // "high", "medium", "low"
        category: string; // "page-builder" ,"Fluent-servce"
        message: string;
        timestamp: string;
        data: LogData;
    };
    exception: {
        values: [
            {
                type: string;
                value: string;
                stacktrace: {
                    frames: [
                        {
                            filename: string;
                            lineno: number;
                            colno: number;
                            rawStacktrace: string;
                        }
                    ]
                }
            }
        ],
        level: string, // "high", "medium", "low"
        location: string; // "./lib/errors.ts",
        logger: string;  // error | warning | info
    };
    title: string;
    user: {
        id: string;
        userName: string;
        ipAddress: string;
        emailId: string;
        geo: {
            countryCode: string;
            region: string;
            city: string;
        };
    }
    constructor(storageProvider: SessionStorageProvider) {
        this.environment = environment.production ? 'production' : 'development';
        this.user = {
            id: storageProvider.getUserAccountId(),
            userName: storageProvider.getUserName(),
            emailId: storageProvider.getUserProfile()?.emailId,
            ipAddress: '',
            geo: {
                countryCode: '',
                region: '',
                city: ''
            }
        };
        this.contexts = {
            browser: {
                name: this.getBrowserName(),
                version: this.getBrowserVersion(),
            }
        }
    }
    private getBrowserName(): string {
        const agent = window.navigator.userAgent.toLowerCase();
        const browser =
            agent.indexOf('edge') > -1 ? 'Microsoft Edge'
                : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
                    : agent.indexOf('opr') > -1 ? 'Opera'
                        : agent.indexOf('chrome') > -1 ? 'Chrome'
                            : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                                : agent.indexOf('firefox') > -1 ? 'Firefox'
                                    : agent.indexOf('safari') > -1 ? 'Safari'
                                        : 'other';

        return browser;
    }

    private getBrowserVersion() {
        var userAgent = navigator.userAgent, tem,
            matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
        return matchTest.join(' ');
    }

    private isMobile(): boolean {
        return window.navigator.maxTouchPoints > 0;
    }
}

export class ClientGeoLog {
    public city: string;
    public region: string;
    public regionCode: string;
    public regionName: string;
    public countryCode: string;
    public countryName: string;
    public continentCode: string;
    public continentName: string;
    public latitude: number;
    public longitude: number;
    public timezone: string;
    public currencyCode: string;
    public currencySymbol: string;
    public currencyConverter: number;
    constructor() { }
}

@Injectable({ providedIn: 'root' })
export class LogService {

    logSubscription = new Subject<DevumEventHandler>();
    logRegistry = new Map<string, DevumEventHandler[]>();
    clientGeoParams: ClientGeoLog;
    constructor(private storageProvider: SessionStorageProvider) {
        // const KEY_TO_READ = environment.production;
        // console.log('KEY_TO_READ', KEY_TO_READ);
        this.getUserRegion();
    }
    getLogSubscriptionAsObservable(){
        return this.logSubscription.asObservable();
    }
    register(info: LogInfo) {
        const logs = this.logRegistry.get(info.group);
        // if (logs) {
        //     logs.push(info);
        //     this.logRegistry.set(info.group, logs);
        // } else {
        //     this.logRegistry.set(info.group, [info]);
        // }
        return this;
    }

    log(message: string, stack: string, category?: string, data?: LogData) {
         console.log("log servcie log -> ", message, stack, data);
         this.report('log', message, stack, category, data);

    }

    warn(message: string, stack: string, category?: string, data?: LogData) {
        console.log("log servcie warn -> ", message, stack, data);

        this.report('info', message, stack, category, data);
    }

    private report(type: string, message: string, stack: string, category: string, data: LogData) {
        let breadcrumbs: {
            type: string; // navigation | http | error | info | warning
            level: string; // "high", "medium", "low"
            category: string; // "ui.click", "xhr", "error", "navigation", "info", "warning"
            message: string;
            timestamp: string;
            data: LogData
        };

        breadcrumbs = {
            type: type,
            level: 'low',
            category: category,
            message: message,
            timestamp: new Date().toISOString(),
            data: data,
        };
        const eventHandler = new DevumEventHandler(this.storageProvider);
        eventHandler.breadcrumbs = breadcrumbs;
        eventHandler.title = message;
        // eventHandler.exception.values.push({ value: stack });
        this.logSubscription.next(eventHandler);
    }

    error(message: string, stack: string, category?: string, data?: LogData) {
         console.log("log servcie error -> ", message, stack, data);
        let fileDetails;
        if(typeof stack ==="string"){
            fileDetails = this.getFileDetails(stack);
        }
        // this.log(fileDetails ? JSON.stringify(fileDetails) : message, message, null, new LogData());
         this.report('Error', fileDetails ? JSON.stringify(fileDetails) : message, message, category || null, data || null)
    }

    info(message: string) {
        this.log(message, message, 'Info');

    }

    getBrowserName(): string {
        const agent = window.navigator.userAgent.toLowerCase();
        const browser =
            agent.indexOf('edge') > -1 ? 'Microsoft Edge'
                : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
                    : agent.indexOf('opr') > -1 ? 'Opera'
                        : agent.indexOf('chrome') > -1 ? 'Chrome'
                            : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                                : agent.indexOf('firefox') > -1 ? 'Firefox'
                                    : agent.indexOf('safari') > -1 ? 'Safari'
                                        : 'other';

        return browser;
    }

    getBrowserVersion() {
        var userAgent = navigator.userAgent, tem,
            matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
        return matchTest.join(' ');
    }

    isMobile(): boolean {
        return window.navigator.maxTouchPoints > 0;
    }

    getUserRegion() {
        try {
            fetch('http://www.geoplugin.net/json.gp')
                .then(response => response.json())
                .then(data => {
                    const geoParams = data as ClientGeoParams
                    this.clientGeoParams = {
                        city: geoParams.geoplugin_city,
                        region: geoParams.geoplugin_region,
                        regionCode: geoParams.geoplugin_regionCode,
                        regionName: geoParams.geoplugin_regionName,
                        countryCode: geoParams.geoplugin_countryCode,
                        countryName: geoParams.geoplugin_countryName,
                        continentCode: geoParams.geoplugin_continentCode,
                        continentName: geoParams.geoplugin_continentName,
                        latitude: geoParams.geoplugin_latitude,
                        longitude: geoParams.geoplugin_longitude,
                        timezone: geoParams.geoplugin_timezone,
                        currencyCode: geoParams.geoplugin_currencyCode,
                        currencySymbol: geoParams.geoplugin_currencySymbol,
                        currencyConverter: geoParams.geoplugin_currencyConverter,
                    };
                });
        } catch {
            this.clientGeoParams = new ClientGeoLog();
        }
    }

    getFileDetails(stack: string) {
        // const stack = trace.split('\n');
        // const fileDetails = stack[1].split('/');
        // const fileName = fileDetails[fileDetails.length - 1];
        // const lineNumber = stack[1].split(':')[1];
        // return { fileName, lineNumber };

        const stackList = stack?.split("\n");
        const regex = /\((.*):(\d+):(\d+)\)$/
        const match = regex.exec(stackList[2]);
        if (match?.length > 4){
            return {
                message: stackList[0],
                filePath: null,
                lineno: null,
                colno: null  
            }
        }
        return {
            message: stackList && stackList[0],
            filePath: match && match[1],
            lineno: match && match[2],
            colno: match && match[3]
        };
    }
}

// export class A {
//     logService: LogService;

//     constructor(logService: LogService) {

//         this.logService = logService.register(new LogInfo())

//         this.logService.log()
//     }
// }