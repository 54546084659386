import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '../../config/config-service';
import { DataEntity } from '../../models/base.models';
import { CacheVersionData } from '../../providers/abstract/app-cache-manager-provider';
import { HttpClientProvider } from '../../providers/abstract/http-client-provider';
import { LoaderServiceProvider } from '../../providers/abstract/loader-service-provider';
import { LocalStorageProvider } from '../../providers/abstract/local-storage-provider';
import { SessionStorageProvider } from '../../providers/abstract/session-storage-provider';
import { EntityCacheManagerProvider } from './entity-cache-manager.provider';
@Injectable()
export class EntityCacheManagerService extends EntityCacheManagerProvider{

    constructor(
        @Inject(LocalStorageProvider) public localStorage: LocalStorageProvider,
        @Inject(LoaderServiceProvider) public loaderService: LoaderServiceProvider,
        @Inject(HttpClientProvider) public http: HttpClientProvider,
        @Inject(ConfigService) public configService: ConfigService,
        @Inject(SessionStorageProvider) public sessionStorageService: SessionStorageProvider,
    ) { 
        super(localStorage, loaderService, http, configService, sessionStorageService);
    }

    removeEntityFromCache(dataEntity: DataEntity, entityCacheMessage?: CacheVersionData) {
        const getListUrl = dataEntity.basePath;
        this.checkAndRemoveFromCache(getListUrl);
        const getPreciseListUrl = dataEntity.basePath + '/precis';
        this.checkAndRemoveFromCache(getPreciseListUrl);
    }
}
