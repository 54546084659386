import { Injectable } from '@angular/core';
import * as enums from './enum-constants';
import { DenBaseEnumApi } from 'projects/den-core';
import { BaseEnumType } from 'projects/den-core/base-models';

@Injectable()
export class BackendActionResponseEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BackendActionResponseEnumConstants.DataViewItem, 'DataViewItem'),
 	 	new BaseEnumType(enums.BackendActionResponseEnumConstants.DataViewTimeSeriesItemContainer, 'DataViewTimeSeriesItemContainer'),
 	 	new BaseEnumType(enums.BackendActionResponseEnumConstants.MultiDataViewContainer, 'MultiDataViewContainer'),
 	 	new BaseEnumType(enums.BackendActionResponseEnumConstants.MultiDataViewTimeSeriesItem, 'MultiDataViewTimeSeriesItem'),
 	 	new BaseEnumType(enums.BackendActionResponseEnumConstants.TwoDMultiDataViewContainer, 'TwoDMultiDataViewContainer'),
 	 	new BaseEnumType(enums.BackendActionResponseEnumConstants.TwoDMultiDataViewTimeSeriesContainer, 'TwoDMultiDataViewTimeSeriesContainer')
        ];
    }
}

@Injectable()
export class DerivedTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DerivedTypeEnumConstants.Api, 'Api'),
 	 	new BaseEnumType(enums.DerivedTypeEnumConstants.MongoQuery, 'MongoQuery'),
 	 	new BaseEnumType(enums.DerivedTypeEnumConstants.ReferenceData, 'ReferenceData')
        ];
    }
}

@Injectable()
export class DeleteStrategyApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DeleteStrategyConstants.CASCADE_DELETE, 'CASCADE_DELETE'),
 	 	new BaseEnumType(enums.DeleteStrategyConstants.FK_CONSTRAINT, 'FK_CONSTRAINT')
        ];
    }
}

@Injectable()
export class BackendActionSupportEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BackendActionSupportEnumConstants.FilterCase, 'FilterCase'),
 	 	new BaseEnumType(enums.BackendActionSupportEnumConstants.Multiple, 'Multiple'),
 	 	new BaseEnumType(enums.BackendActionSupportEnumConstants.Single, 'Single')
        ];
    }
}

@Injectable()
export class DevumNotificationTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumNotificationTypeEnumConstants.Apps, 'Apps'),
 	 	new BaseEnumType(enums.DevumNotificationTypeEnumConstants.Email, 'Email'),
 	 	new BaseEnumType(enums.DevumNotificationTypeEnumConstants.SMS, 'SMS'),
 	 	new BaseEnumType(enums.DevumNotificationTypeEnumConstants.WhatsApp, 'WhatsApp')
        ];
    }
}

@Injectable()
export class LocalUserStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.LocalUserStatusEnumConstants.ARCHIVED, 'ARCHIVED'),
 	 	new BaseEnumType(enums.LocalUserStatusEnumConstants.COMPROMISED, 'COMPROMISED'),
 	 	new BaseEnumType(enums.LocalUserStatusEnumConstants.CONFIRMED, 'CONFIRMED'),
 	 	new BaseEnumType(enums.LocalUserStatusEnumConstants.RESET_REQUIRED, 'RESET_REQUIRED'),
 	 	new BaseEnumType(enums.LocalUserStatusEnumConstants.UNCONFIRMED, 'UNCONFIRMED'),
 	 	new BaseEnumType(enums.LocalUserStatusEnumConstants.UNKNOWN, 'UNKNOWN')
        ];
    }
}

@Injectable()
export class ReducerEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ReducerEnumConstants.Average, 'Average'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Concat, 'Concat'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Distinct, 'Distinct'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Expression, 'Expression'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Head, 'Head'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Last, 'Last'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Percentage, 'Percentage'),
 	 	new BaseEnumType(enums.ReducerEnumConstants.Summation, 'Summation')
        ];
    }
}

@Injectable()
export class TimeDurationTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TimeDurationTypeEnumConstants.Days, 'Days'),
 	 	new BaseEnumType(enums.TimeDurationTypeEnumConstants.Hours, 'Hours'),
 	 	new BaseEnumType(enums.TimeDurationTypeEnumConstants.MilliSeconds, 'MilliSeconds'),
 	 	new BaseEnumType(enums.TimeDurationTypeEnumConstants.Minutes, 'Minutes'),
 	 	new BaseEnumType(enums.TimeDurationTypeEnumConstants.Seconds, 'Seconds')
        ];
    }
}

@Injectable()
export class TestCaseModeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TestCaseModeConstants.SIMULATION, 'SIMULATION'),
 	 	new BaseEnumType(enums.TestCaseModeConstants.TESTING, 'TESTING')
        ];
    }
}

@Injectable()
export class SpatialDataFormatEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SpatialDataFormatEnumConstants.BlockModel, 'BlockModel'),
 	 	new BaseEnumType(enums.SpatialDataFormatEnumConstants.Contour, 'Contour'),
 	 	new BaseEnumType(enums.SpatialDataFormatEnumConstants.General, 'General'),
 	 	new BaseEnumType(enums.SpatialDataFormatEnumConstants.Route, 'Route')
        ];
    }
}

@Injectable()
export class AppArtifactTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.AppArtifactTypeEnumConstants.DataModeller, 'DataModeller'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.FORM, 'FORM'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.FluentService, 'FluentService'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.GENERAL, 'GENERAL'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.MView, 'MView'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.PAGE, 'PAGE'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.ProcessModeller, 'ProcessModeller'),
 	 	new BaseEnumType(enums.AppArtifactTypeEnumConstants.REPORTS, 'REPORTS')
        ];
    }
}

@Injectable()
export class ProcessInstanceStateEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ProcessInstanceStateEnumConstants.ACTIVE, 'ACTIVE'),
 	 	new BaseEnumType(enums.ProcessInstanceStateEnumConstants.CANCELLED, 'CANCELLED'),
 	 	new BaseEnumType(enums.ProcessInstanceStateEnumConstants.COMPLETED, 'COMPLETED'),
 	 	new BaseEnumType(enums.ProcessInstanceStateEnumConstants.FAILED, 'FAILED')
        ];
    }
}

@Injectable()
export class TaskPriorityEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TaskPriorityEnumConstants.High, 'High'),
 	 	new BaseEnumType(enums.TaskPriorityEnumConstants.Low, 'Low'),
 	 	new BaseEnumType(enums.TaskPriorityEnumConstants.Medium, 'Medium')
        ];
    }
}

@Injectable()
export class TemplateAndDeploymentStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TemplateAndDeploymentStatusEnumConstants.Completed, 'Completed'),
 	 	new BaseEnumType(enums.TemplateAndDeploymentStatusEnumConstants.Failed, 'Failed'),
 	 	new BaseEnumType(enums.TemplateAndDeploymentStatusEnumConstants.InProgress, 'InProgress')
        ];
    }
}

@Injectable()
export class FileGroupApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.FileGroupConstants.app, 'app'),
 	 	new BaseEnumType(enums.FileGroupConstants.client, 'client'),
 	 	new BaseEnumType(enums.FileGroupConstants.private, 'private'),
 	 	new BaseEnumType(enums.FileGroupConstants.public, 'public')
        ];
    }
}

@Injectable()
export class CharvakaFunctionTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CharvakaFunctionTypeEnumConstants.ASYNC, 'ASYNC'),
 	 	new BaseEnumType(enums.CharvakaFunctionTypeEnumConstants.DevumStream, 'DevumStream'),
 	 	new BaseEnumType(enums.CharvakaFunctionTypeEnumConstants.REACTIVE, 'REACTIVE'),
 	 	new BaseEnumType(enums.CharvakaFunctionTypeEnumConstants.ReactiveStream, 'ReactiveStream')
        ];
    }
}

@Injectable()
export class ArtifactGroupApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ArtifactGroupConstants.APP, 'APP'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.DATA_MODELLER, 'DATA_MODELLER'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.DYNAMIC_FORM, 'DYNAMIC_FORM'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.FLUENT_SERVICE, 'FLUENT_SERVICE'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.MICROSERVICE, 'MICROSERVICE'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.MISCELLANEOUS, 'MISCELLANEOUS'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.MOBILE_APP, 'MOBILE_APP'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.MVIEW, 'MVIEW'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.NOT_PROVIDED, 'NOT_PROVIDED'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.PAGE, 'PAGE'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.PROCESS, 'PROCESS'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.REPORT, 'REPORT'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.SITE, 'SITE'),
 	 	new BaseEnumType(enums.ArtifactGroupConstants.USER_ACCOUNT, 'USER_ACCOUNT')
        ];
    }
}

@Injectable()
export class BuildStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BuildStatusEnumConstants.Cancelled, 'Cancelled'),
 	 	new BaseEnumType(enums.BuildStatusEnumConstants.Completed, 'Completed'),
 	 	new BaseEnumType(enums.BuildStatusEnumConstants.Created, 'Created'),
 	 	new BaseEnumType(enums.BuildStatusEnumConstants.Failed, 'Failed'),
 	 	new BaseEnumType(enums.BuildStatusEnumConstants.InProgress, 'InProgress'),
 	 	new BaseEnumType(enums.BuildStatusEnumConstants.Queued, 'Queued')
        ];
    }
}

@Injectable()
export class ArtifactStatusTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ArtifactStatusTypeEnumConstants.INFO, 'INFO'),
 	 	new BaseEnumType(enums.ArtifactStatusTypeEnumConstants.SHOW_STOPPER, 'SHOW_STOPPER'),
 	 	new BaseEnumType(enums.ArtifactStatusTypeEnumConstants.WARN, 'WARN')
        ];
    }
}

@Injectable()
export class SchemaTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SchemaTypeEnumConstants.MView, 'MView'),
 	 	new BaseEnumType(enums.SchemaTypeEnumConstants.MView_Params, 'MView_Params'),
 	 	new BaseEnumType(enums.SchemaTypeEnumConstants.Master, 'Master'),
 	 	new BaseEnumType(enums.SchemaTypeEnumConstants.Reference, 'Reference'),
 	 	new BaseEnumType(enums.SchemaTypeEnumConstants.Transaction, 'Transaction')
        ];
    }
}

@Injectable()
export class DevumNotificationThresholdTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Daily, 'Daily'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Fifteen_Minutes, 'Fifteen Minutes'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.One_Hour, 'One Hour'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Six_Hours, 'Six Hours'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Thirty_Minutes, 'Thirty Minutes'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Twelve_Hours, 'Twelve Hours'),
 	 	new BaseEnumType(enums.DevumNotificationThresholdTypeConstants.Two_Hours, 'Two Hours')
        ];
    }
}

@Injectable()
export class BackendActionTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BackendActionTypeEnumConstants.Form, 'Form'),
 	 	new BaseEnumType(enums.BackendActionTypeEnumConstants.MView, 'MView')
        ];
    }
}

@Injectable()
export class BeforeOrAfterApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.BeforeOrAfterConstants.After, 'After'),
 	 	new BaseEnumType(enums.BeforeOrAfterConstants.Before, 'Before')
        ];
    }
}

@Injectable()
export class UsageEvaluationApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.UsageEvaluationConstants.NOT_PROVIDED, 'NOT_PROVIDED'),
 	 	new BaseEnumType(enums.UsageEvaluationConstants.PROVIDED, 'PROVIDED')
        ];
    }
}

@Injectable()
export class AppDeploymentTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.AppDeploymentTypeConstants.EXISTING, 'EXISTING'),
 	 	new BaseEnumType(enums.AppDeploymentTypeConstants.NEW, 'NEW')
        ];
    }
}

@Injectable()
export class CharvakaMViewTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CharvakaMViewTypeConstants.All_Tasks, 'All Tasks'),
 	 	new BaseEnumType(enums.CharvakaMViewTypeConstants.Process_Specific, 'Process Specific'),
 	 	new BaseEnumType(enums.CharvakaMViewTypeConstants.Task_Specific, 'Task Specific')
        ];
    }
}

@Injectable()
export class MongoRepoFunctionsEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.MongoRepoFunctionsEnumConstants.aggregate, 'aggregate'),
 	 	new BaseEnumType(enums.MongoRepoFunctionsEnumConstants.find, 'find'),
 	 	new BaseEnumType(enums.MongoRepoFunctionsEnumConstants.findOne, 'findOne'),
 	 	new BaseEnumType(enums.MongoRepoFunctionsEnumConstants.projection, 'projection'),
 	 	new BaseEnumType(enums.MongoRepoFunctionsEnumConstants.result, 'result')
        ];
    }
}

@Injectable()
export class ControlConditionTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ControlConditionTypeEnumConstants.All, 'All'),
 	 	new BaseEnumType(enums.ControlConditionTypeEnumConstants.Any, 'Any')
        ];
    }
}

@Injectable()
export class MongoDbOperationTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.deleteById, 'deleteById'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.deleteByQuery, 'deleteByQuery'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.deleteMany, 'deleteMany'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.deleteManyWithLimitAndSort, 'deleteManyWithLimitAndSort'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.disableByQuery, 'disableByQuery'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.enableByQuery, 'enableByQuery'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.insert, 'insert'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.insertMany, 'insertMany'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.setById, 'setById'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.setMany, 'setMany'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.updateById, 'updateById'),
 	 	new BaseEnumType(enums.MongoDbOperationTypeEnumConstants.updateMany, 'updateMany')
        ];
    }
}

@Injectable()
export class SchemaBehaviourTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SchemaBehaviourTypeEnumConstants.Non_Process, 'Non-Process'),
 	 	new BaseEnumType(enums.SchemaBehaviourTypeEnumConstants.Process, 'Process')
        ];
    }
}

@Injectable()
export class DevumNotificationSeverityEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumNotificationSeverityEnumConstants.High, 'High'),
 	 	new BaseEnumType(enums.DevumNotificationSeverityEnumConstants.Low, 'Low'),
 	 	new BaseEnumType(enums.DevumNotificationSeverityEnumConstants.Medium, 'Medium')
        ];
    }
}

@Injectable()
export class PgTableEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PgTableEnumConstants.AdvancedEntitySchemaSetting, 'AdvancedEntitySchemaSetting'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.AdvancedSchemaFieldSetting, 'AdvancedSchemaFieldSetting'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.BoundedContext, 'BoundedContext'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.BoundedContextSchemaMapping, 'BoundedContextSchemaMapping'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.CharvakaFluentFunctionComposition, 'CharvakaFluentFunctionComposition'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.CharvakaFluentService, 'CharvakaFluentService'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.CharvakaProcess, 'CharvakaProcess'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.CharvakaProcessDefinition, 'CharvakaProcessDefinition'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.CharvakaProcessSpatialConfiguration, 'CharvakaProcessSpatialConfiguration'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.ComposerGroup, 'ComposerGroup'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.ControlInstance, 'ControlInstance'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.DataSourceServiceInstance, 'DataSourceServiceInstance'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntityFieldCondition, 'EntityFieldCondition'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntityFieldConditionOperator, 'EntityFieldConditionOperator'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntitySchema, 'EntitySchema'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntitySchemaField, 'EntitySchemaField'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntitySchemaFieldFilter, 'EntitySchemaFieldFilter'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntitySchemaPrecis, 'EntitySchemaPrecis'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.EntitySchemaPrecisMapping, 'EntitySchemaPrecisMapping'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.Help, 'Help'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.HelpCategory, 'HelpCategory'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.MViewParamsMapping, 'MViewParamsMapping'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.MViewTransactionDateMapping, 'MViewTransactionDateMapping'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.MaterializedViewDefinition, 'MaterializedViewDefinition'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.ModelConfiguration, 'ModelConfiguration'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.Page, 'Page'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.PageParameter, 'PageParameter'),
 	 	new BaseEnumType(enums.PgTableEnumConstants.QuickStyleTemplate, 'QuickStyleTemplate')
        ];
    }
}

@Injectable()
export class OperationCrudTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.OperationCrudTypeEnumConstants.Delete, 'Delete'),
 	 	new BaseEnumType(enums.OperationCrudTypeEnumConstants.Insert, 'Insert'),
 	 	new BaseEnumType(enums.OperationCrudTypeEnumConstants.Move, 'Move'),
 	 	new BaseEnumType(enums.OperationCrudTypeEnumConstants.Update, 'Update')
        ];
    }
}

@Injectable()
export class SpatialCoordinateSystemEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SpatialCoordinateSystemEnumConstants.UTM_system, 'UTM system'),
 	 	new BaseEnumType(enums.SpatialCoordinateSystemEnumConstants.WGS_system, 'WGS system')
        ];
    }
}

@Injectable()
export class EnvironmentTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.EnvironmentTypeConstants.DEV, 'DEV'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.PROD, 'PROD'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.QA, 'QA'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.UAT, 'UAT'),
 	 	new BaseEnumType(enums.EnvironmentTypeConstants.shared, 'shared')
        ];
    }
}

@Injectable()
export class SubscriptionPlanTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SubscriptionPlanTypeEnumConstants.Enterprise, 'Enterprise'),
 	 	new BaseEnumType(enums.SubscriptionPlanTypeEnumConstants.Free, 'Free'),
 	 	new BaseEnumType(enums.SubscriptionPlanTypeEnumConstants.Premium, 'Premium'),
 	 	new BaseEnumType(enums.SubscriptionPlanTypeEnumConstants.Professional, 'Professional')
        ];
    }
}

@Injectable()
export class PeriodApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PeriodConstants.DAYS, 'DAYS'),
 	 	new BaseEnumType(enums.PeriodConstants.Daily, 'Daily'),
 	 	new BaseEnumType(enums.PeriodConstants.HOURS, 'HOURS'),
 	 	new BaseEnumType(enums.PeriodConstants.Hourly, 'Hourly'),
 	 	new BaseEnumType(enums.PeriodConstants.MINUTES, 'MINUTES'),
 	 	new BaseEnumType(enums.PeriodConstants.MONTHS, 'MONTHS'),
 	 	new BaseEnumType(enums.PeriodConstants.Monthly, 'Monthly'),
 	 	new BaseEnumType(enums.PeriodConstants.NoPeriod, 'NoPeriod'),
 	 	new BaseEnumType(enums.PeriodConstants.Quarterly, 'Quarterly'),
 	 	new BaseEnumType(enums.PeriodConstants.SECONDS, 'SECONDS'),
 	 	new BaseEnumType(enums.PeriodConstants.WEEKS, 'WEEKS'),
 	 	new BaseEnumType(enums.PeriodConstants.Weekly, 'Weekly'),
 	 	new BaseEnumType(enums.PeriodConstants.YEARS, 'YEARS'),
 	 	new BaseEnumType(enums.PeriodConstants.Yearly, 'Yearly')
        ];
    }
}

@Injectable()
export class ControlTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ControlTypeEnumConstants.API, 'API'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.CHECK_BOX, 'CHECK_BOX'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.CHECK_BOX_LIST, 'CHECK_BOX_LIST'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.COLOR_PICKER, 'COLOR_PICKER'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.DATE_PICKER, 'DATE_PICKER'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.DATE_TIME_PICKER, 'DATE_TIME_PICKER'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.FILE_UPLOAD, 'FILE_UPLOAD'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.FOREIGN_KEY, 'FOREIGN_KEY'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.GEO_FENCE, 'GEO_FENCE'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.GEO_LOCATION, 'GEO_LOCATION'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.LOCATION3D, 'LOCATION3D'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.ORIENTATION, 'ORIENTATION'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.QUESTIONNAIRE, 'QUESTIONNAIRE'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.RADIO_BUTTON_LIST, 'RADIO_BUTTON_LIST'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.RESOURCE_BROWSER, 'RESOURCE_BROWSER'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.RESOURCE_BROWSER_3D, 'RESOURCE_BROWSER_3D'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.RICH_TEXT, 'RICH_TEXT'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.SCALE, 'SCALE'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.STATIC_SELECT, 'STATIC_SELECT'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.TEXT_AREA, 'TEXT_AREA'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.TEXT_BOX, 'TEXT_BOX'),
 	 	new BaseEnumType(enums.ControlTypeEnumConstants.TIME_PICKER, 'TIME_PICKER')
        ];
    }
}

@Injectable()
export class CharvakaFunctionGroupEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Cache, 'Cache'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Collection, 'Collection'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Conditional, 'Conditional'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Conversion, 'Conversion'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Custom, 'Custom'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.DB, 'DB'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Date, 'Date'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Exception, 'Exception'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Integration, 'Integration'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Logger, 'Logger'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Math, 'Math'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Notification, 'Notification'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Pub_sub, 'Pub-sub'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Reactive, 'Reactive'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Reactive_Streaming, 'Reactive Streaming'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.String, 'String'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Types, 'Types'),
 	 	new BaseEnumType(enums.CharvakaFunctionGroupEnumConstants.Util, 'Util')
        ];
    }
}

@Injectable()
export class SpatialFileEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SpatialFileEnumConstants.CSV_file, 'CSV file'),
 	 	new BaseEnumType(enums.SpatialFileEnumConstants.DXF_file, 'DXF file'),
 	 	new BaseEnumType(enums.SpatialFileEnumConstants.Shape_file, 'Shape file')
        ];
    }
}

@Injectable()
export class WebSocketStrategyApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.WebSocketStrategyConstants.Aggregated, 'Aggregated'),
 	 	new BaseEnumType(enums.WebSocketStrategyConstants.IndividualItem, 'IndividualItem')
        ];
    }
}

@Injectable()
export class RestMethodTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.RestMethodTypeConstants.DELETE, 'DELETE'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.FILTER_WITH_PAGINATION, 'FILTER_WITH_PAGINATION'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.FORM_VIEW, 'FORM_VIEW'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.GET, 'GET'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.GET_ALL, 'GET_ALL'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.M_VIEW, 'M_VIEW'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.POST, 'POST'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.POST_FILTER, 'POST_FILTER'),
 	 	new BaseEnumType(enums.RestMethodTypeConstants.SELF_FILTER, 'SELF_FILTER')
        ];
    }
}

@Injectable()
export class NotificationTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.NotificationTypeEnumConstants.AdminRoleLogged, 'AdminRoleLogged'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Assigned, 'Assigned'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Available, 'Available'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Claimed, 'Claimed'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Completed, 'Completed'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Deleted, 'Deleted'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.InitiatorRoleLogged, 'InitiatorRoleLogged'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Notification_Logged, 'Notification_Logged'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.Overdue, 'Overdue'),
 	 	new BaseEnumType(enums.NotificationTypeEnumConstants.ProcessDefinitionDeleted, 'ProcessDefinitionDeleted')
        ];
    }
}

@Injectable()
export class DataSourceOwnerTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DataSourceOwnerTypeEnumConstants.control_instance, 'control_instance'),
 	 	new BaseEnumType(enums.DataSourceOwnerTypeEnumConstants.dialog_button, 'dialog_button'),
 	 	new BaseEnumType(enums.DataSourceOwnerTypeEnumConstants.event_action, 'event_action')
        ];
    }
}

@Injectable()
export class UsageSeverityApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.UsageSeverityConstants.DEPENDENCY, 'DEPENDENCY'),
 	 	new BaseEnumType(enums.UsageSeverityConstants.FK_DEPENDENCY, 'FK_DEPENDENCY'),
 	 	new BaseEnumType(enums.UsageSeverityConstants.INFO, 'INFO'),
 	 	new BaseEnumType(enums.UsageSeverityConstants.WARNING, 'WARNING')
        ];
    }
}

@Injectable()
export class ActualEntityStateTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ActualEntityStateTypeConstants.DELETE, 'DELETE'),
 	 	new BaseEnumType(enums.ActualEntityStateTypeConstants.INSERT, 'INSERT'),
 	 	new BaseEnumType(enums.ActualEntityStateTypeConstants.UPDATE, 'UPDATE')
        ];
    }
}

@Injectable()
export class ComparisonResultTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ComparisonResultTypeConstants.DELETED, 'DELETED'),
 	 	new BaseEnumType(enums.ComparisonResultTypeConstants.NEW, 'NEW'),
 	 	new BaseEnumType(enums.ComparisonResultTypeConstants.UNCHANGED, 'UNCHANGED'),
 	 	new BaseEnumType(enums.ComparisonResultTypeConstants.UPDATED, 'UPDATED')
        ];
    }
}

@Injectable()
export class TemplateTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TemplateTypeConstants.client_template, 'client_template'),
 	 	new BaseEnumType(enums.TemplateTypeConstants.devum_distributed_template, 'devum_distributed_template')
        ];
    }
}

@Injectable()
export class SchemaFieldCategoryEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.SchemaFieldCategoryEnumConstants.FormField, 'FormField'),
 	 	new BaseEnumType(enums.SchemaFieldCategoryEnumConstants.KpiInField, 'KpiInField'),
 	 	new BaseEnumType(enums.SchemaFieldCategoryEnumConstants.KpiOutField, 'KpiOutField'),
 	 	new BaseEnumType(enums.SchemaFieldCategoryEnumConstants.ProcessField, 'ProcessField'),
 	 	new BaseEnumType(enums.SchemaFieldCategoryEnumConstants.TaskField, 'TaskField')
        ];
    }
}

@Injectable()
export class UserOperationTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.UserOperationTypeConstants.deleted, 'deleted'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.disabled, 'disabled'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.discarded, 'discarded'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.dynamic, 'dynamic'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.enabled, 'enabled'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.insert_updated, 'insert_updated'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.inserted, 'inserted'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.published, 'published'),
 	 	new BaseEnumType(enums.UserOperationTypeConstants.updated, 'updated')
        ];
    }
}

@Injectable()
export class ReportParamsTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ReportParamsTypeEnumConstants.Date, 'Date'),
 	 	new BaseEnumType(enums.ReportParamsTypeEnumConstants.DateRange, 'DateRange'),
 	 	new BaseEnumType(enums.ReportParamsTypeEnumConstants.None, 'None')
        ];
    }
}

@Injectable()
export class ProcessExecutionStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ProcessExecutionStatusEnumConstants.FAILED, 'FAILED'),
 	 	new BaseEnumType(enums.ProcessExecutionStatusEnumConstants.RETRYING, 'RETRYING'),
 	 	new BaseEnumType(enums.ProcessExecutionStatusEnumConstants.SUCCESS, 'SUCCESS')
        ];
    }
}

@Injectable()
export class ClientTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ClientTypeEnumConstants.api, 'api'),
 	 	new BaseEnumType(enums.ClientTypeEnumConstants.user, 'user')
        ];
    }
}

@Injectable()
export class TableTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TableTypeEnumConstants.Dynamic, 'Dynamic'),
 	 	new BaseEnumType(enums.TableTypeEnumConstants.Static, 'Static')
        ];
    }
}

@Injectable()
export class DevumFeaturesEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DevumFeaturesEnumConstants.Access_to_Enterprise_Templates, 'Access to Enterprise Templates'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Access_to_Premium_Templates, 'Access to Premium Templates'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Access_to_template_library, 'Access to template library'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Amazon_single_Sign_on, 'Amazon single Sign-on'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.App_deployment, 'App deployment'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Apple_single_sign_on, 'Apple single sign-on'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Application_health_monitoring, 'Application health monitoring'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Apps, 'Apps'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Automated_Database_backups, 'Automated Database backups'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Automated_core_service_updates, 'Automated core service updates'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Built_in_Microservices, 'Built-in Microservices'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Create_own_templates, 'Create own templates'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Custom_microservices, 'Custom microservices'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Dedicated_account_manager, 'Dedicated account manager'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Devum_Objects, 'Devum Objects'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Domain_modeller, 'Domain modeller'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Domain_service_builder, 'Domain service builder'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Dynamic_Dev_users_configuration, 'Dynamic Dev users configuration'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Enterprise_SAML, 'Enterprise SAML'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Environments, 'Environments'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Facebook_single_Sign_on, 'Facebook single Sign-on'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Google_single_Sign_on, 'Google single Sign-on'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Load_balancing, 'Load balancing'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Log_monitoring, 'Log monitoring'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Login_UI_customization, 'Login UI customization'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Microsoft_Azure_AD, 'Microsoft Azure AD'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Multi_factor_authentication, 'Multi-factor authentication'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Node_configuration, 'Node configuration'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Number_of_Nodes, 'Number of Nodes'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Page_builder, 'Page builder'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Real_time_MViews, 'Real-time MViews'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Report_builder, 'Report builder'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Resource_adjustment, 'Resource adjustment'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Support_plan, 'Support plan'),
 	 	new BaseEnumType(enums.DevumFeaturesEnumConstants.Users, 'Users')
        ];
    }
}

@Injectable()
export class DisposalTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DisposalTypeConstants.Delete, 'Delete'),
 	 	new BaseEnumType(enums.DisposalTypeConstants.DisableDelete, 'DisableDelete')
        ];
    }
}

@Injectable()
export class DatasourceCrudTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.DatasourceCrudTypeConstants.DELETE, 'DELETE'),
 	 	new BaseEnumType(enums.DatasourceCrudTypeConstants.INSERT, 'INSERT'),
 	 	new BaseEnumType(enums.DatasourceCrudTypeConstants.UPDATE, 'UPDATE')
        ];
    }
}

@Injectable()
export class IdentityProviderTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.IdentityProviderTypeEnumConstants.Facebook, 'Facebook'),
 	 	new BaseEnumType(enums.IdentityProviderTypeEnumConstants.Google, 'Google'),
 	 	new BaseEnumType(enums.IdentityProviderTypeEnumConstants.LoginWithAmazon, 'LoginWithAmazon'),
 	 	new BaseEnumType(enums.IdentityProviderTypeEnumConstants.SAML, 'SAML')
        ];
    }
}

@Injectable()
export class AuditLogLevelEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.AuditLogLevelEnumConstants.Advanced, 'Advanced'),
 	 	new BaseEnumType(enums.AuditLogLevelEnumConstants.Basic, 'Basic'),
 	 	new BaseEnumType(enums.AuditLogLevelEnumConstants.None, 'None')
        ];
    }
}

@Injectable()
export class ConditionalOperatorEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.ConditionalOperatorEnumConstants.AND, 'AND'),
 	 	new BaseEnumType(enums.ConditionalOperatorEnumConstants.OR, 'OR')
        ];
    }
}

@Injectable()
export class OperatingSystemTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.OperatingSystemTypeEnumConstants.Android, 'Android'),
 	 	new BaseEnumType(enums.OperatingSystemTypeEnumConstants.IOS, 'IOS'),
 	 	new BaseEnumType(enums.OperatingSystemTypeEnumConstants.Windows, 'Windows')
        ];
    }
}

@Injectable()
export class TaskStatusEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.TaskStatusEnumConstants.Cancelled, 'Cancelled'),
 	 	new BaseEnumType(enums.TaskStatusEnumConstants.Completed, 'Completed'),
 	 	new BaseEnumType(enums.TaskStatusEnumConstants.Failed, 'Failed'),
 	 	new BaseEnumType(enums.TaskStatusEnumConstants.Overdue, 'Overdue'),
 	 	new BaseEnumType(enums.TaskStatusEnumConstants.Pending, 'Pending'),
 	 	new BaseEnumType(enums.TaskStatusEnumConstants.Unclaimed, 'Unclaimed')
        ];
    }
}

@Injectable()
export class GroupAndReduceByApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.GroupAndReduceByConstants.by_date, 'by_date'),
 	 	new BaseEnumType(enums.GroupAndReduceByConstants.by_fk, 'by_fk'),
 	 	new BaseEnumType(enums.GroupAndReduceByConstants.reduce_all, 'reduce_all')
        ];
    }
}

@Injectable()
export class FixedRoleEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.FixedRoleEnumConstants.App_Admin, 'App_Admin'),
 	 	new BaseEnumType(enums.FixedRoleEnumConstants.App_Committer, 'App_Committer'),
 	 	new BaseEnumType(enums.FixedRoleEnumConstants.App_Developer, 'App_Developer'),
 	 	new BaseEnumType(enums.FixedRoleEnumConstants.App_Ops_Manager, 'App_Ops_Manager'),
 	 	new BaseEnumType(enums.FixedRoleEnumConstants.Authenticated_User, 'Authenticated_User'),
 	 	new BaseEnumType(enums.FixedRoleEnumConstants.Super_Admin, 'Super_Admin')
        ];
    }
}

@Injectable()
export class FluentServiceUtilizerTypeApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.BackendActionMethod, 'BackendActionMethod'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.BasicFluentService, 'BasicFluentService'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.DevumStreamingService, 'DevumStreamingService'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.EntityPrePostProcessor, 'EntityPrePostProcessor'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.FluentServiceTask, 'FluentServiceTask'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.FormField, 'FormField'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.MaterializedViewBuilder, 'MaterializedViewBuilder'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.NA, 'NA'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.PostUserActionProcessor, 'PostUserActionProcessor'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.ReactiveFluentService, 'ReactiveFluentService'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.RestResponderCFSBridgeService, 'RestResponderCFSBridgeService'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.Start, 'Start'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.Status, 'Status'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.Stop, 'Stop'),
 	 	new BaseEnumType(enums.FluentServiceUtilizerTypeConstants.TimerFluentService, 'TimerFluentService')
        ];
    }
}

@Injectable()
export class PageTypeEnumApi extends DenBaseEnumApi {
    constructor() {
        super();
    }

    protected getEnumOptions(): BaseEnumType[] {
        return [
        new BaseEnumType(enums.PageTypeEnumConstants.Mobile, 'Mobile'),
 	 	new BaseEnumType(enums.PageTypeEnumConstants.Report, 'Report'),
 	 	new BaseEnumType(enums.PageTypeEnumConstants.Web, 'Web')
        ];
    }
}
