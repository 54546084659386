import { differenceInMinutes, format, getHours, getMinutes, parse } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { TIME_ZONES } from '../constants/app-constants';
import { DateRegex } from '../constants/constant';
import { SharedHelper } from '../page-builder/helpers/shared-helper';
import { OrganizationDate } from "./org-date-helper";

export class DateTimeHelper {
    static ISO_DATE_TIME: RegExp;

    static convertToTimeString(dateTimeInMilliSeconds: string | number | Date | any) {
        var d = new Date(dateTimeInMilliSeconds);
        var h = d.getHours();
        var m: any = d.getMinutes();
        var s: any = d.getSeconds();
        var tms = dateTimeInMilliSeconds / 1000;
        var dec = (tms - Math.floor(tms)).toFixed(2).slice(1);
        var mer = 'AM';
        if (h > 11) {
            h %= 12;
            mer = 'PM';
        }
        if (h === 0) h = 12;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return h + ':' + m + ':' + s + dec + ' ' + mer;
    }

    static convertToDateString(dateTimeInMilliSeconds: string | number | Date) {
        return new Date(dateTimeInMilliSeconds).toDateString();
    }

    static convertMinuteIntoHours(minutes: number) {
        const min = minutes % 60;
        const hours = Math.floor(minutes / 60);
        return hours === 0 ? + Math.floor(min) + " min" : hours + " hr " + Math.floor(min) + " min";
    }

    static getMinutesBetweenDates(startDate: string, endDate: string) {
        return differenceInMinutes(new Date(endDate), new Date(startDate))
    }

    static convertDateTimeToTime(date: string) {
        const timeNow = new Date(date);
        const hours = getHours(timeNow).toString();
        const minutes = getMinutes(timeNow).toString().length === 1 ? "0" + getMinutes(timeNow).toString() : getMinutes(timeNow);
        return hours + ":" + minutes;
    }

    static isUtcDateTime(value: string) {
        this.ISO_DATE_TIME = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;
        return this.ISO_DATE_TIME.test(value);
    }


    static recursivelyConvertUTCDateTimeToOrgTime(data: any, obj: { [x: string]: string | null; } | null, property: string | null) {
        if (data != null && data != undefined) {
            if (typeof data === 'object') {
                if (data.constructor === Array) {
                    for (var i = 0; i < data.length; i++) {
                        DateTimeHelper.recursivelyConvertUTCDateTimeToOrgTime(data[i], null, null);
                    }
                } else {
                    for (var propertyName in data) {
                        const parsedData = SharedHelper.isJson(data[propertyName]) ? JSON.parse(data[propertyName]) : data[propertyName];
                        DateTimeHelper.recursivelyConvertUTCDateTimeToOrgTime(parsedData, data, propertyName);
                    }
                }
            }
            else if (obj && property && DateTimeHelper.isUtcDateTime(data)) {
                obj[property] = OrganizationDate.convertIsoUtcDateTimeToOrgDateTime(data);
            }
        }
    }
    static parseDateString(date: string | Date): Date {
        if (date instanceof Date) {
            return date
        }
        let parsedDate: Date;
        if (typeof date === 'string' && DateRegex.ddMMyyyy.test(date as string)) {
            parsedDate = DateTimeHelper.getParsedDate(date, 'dd-MM-yyyy');
        } else if (typeof date === 'string' && DateRegex.yyyyMMdd.test(date as string)) {
            parsedDate = DateTimeHelper.getParsedDate(date, 'yyyy-MM-dd');
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmssSSSZ.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmssSSSZ.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmssZ.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmssSSSXXX.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmssXXX.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddHHmmss.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd HH:mm:ss");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddHHmm.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd HH:mm");
        } else if (typeof date === 'string' && DateRegex.ddMMyyyyHHmmss.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "dd-MM-yyyy HH:mm:ss");
        } else if (typeof date === 'string' && DateRegex.ddMMyyyyHHmm.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "dd-MM-yyyy HH:mm");
        } else if (typeof date === 'string' && DateRegex.HHmm.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "HH:mm");
        } else if (typeof date === 'string' && DateRegex.yyyyMM.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM");
        } else if (typeof date === 'string' && DateRegex.yyyyMMddTHHmmss.test(date)) {
            parsedDate = DateTimeHelper.getParsedDate(date, "yyyy-MM-dd'T'HH:mm:ss");
        }else {
            // console.info('Please pass valid date string only')
            parsedDate = null;
        }
        return parsedDate;
    }
    private static getParsedDate(date: string, inputFormat: string) {
        const dateObj = parse(date, inputFormat, DateTimeHelper.getCurrentDateInOrgTimezone());
        return dateObj;
    }
    /**
     * to resolve in date without timezone.
     * @param desiredFormat 
     * @param date 
     * @returns 
     */
    static getFormattedDateString(desiredFormat: string, date?: Date | string): string {
        if (!date) {
            date = DateTimeHelper.getCurrentDateInOrgTimezone();
        }
        date = this.parseDateString(date)
        return format(date as Date, desiredFormat)
    }
    // static getFormattedDateString(desiredFormat: string, date?: Date | string): string {
    //     if (!date) {
    //         date = DateTimeHelper.getCurrentDateInOrgTimezone();
    //     }
    //     date = this.parseDateString(date)
    //     return this.formatDate(date as Date, desiredFormat)
    // }
    static formatDate(date: Date, desiredFormat: string) {
        const timeZone = OrganizationDate.getSiteTimezone() || OrganizationDate.getOrgTimeZone()
        return formatInTimeZone(date, timeZone, desiredFormat)

    }
    static getTimeZones(): string[] {
        return TIME_ZONES;
    }

    static getCurrentDateInOrgTimezone(): Date {
        const timeZone = OrganizationDate.getSiteTimezone() || OrganizationDate.getOrgTimeZone()
        const now = utcToZonedTime(Date.parse(new Date().toISOString()), timeZone)
        return now
    }

    static convertLocalTimeToUTC(time: string): string {
        const [hours, minutes] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes)
        return this.formatTime(date.getUTCHours(), date.getUTCMinutes());
    }

    static convertUTCToLocalTime(time: string): string {
        const [utcHours, utcMinutes] = time.split(':').map(Number);
        const date = new Date();
        const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), utcHours, utcMinutes, 0));
        return this.formatTime(utcDate.getHours(), utcDate.getMinutes());
    }

    private static formatTime(hours: number, minutes: number): string {
        const hoursAsString = hours.toString().padStart(2, '0');
        const minutesAsString = minutes.toString().padStart(2, '0');
        return `${hoursAsString}:${minutesAsString}`;
    }


    /**
   * Converts UTC datetime to ISO datetime
   * @param utcDateTime in string format. Ex: 2024-01-30T11:07:37.000Z
   * @param timeZone in string format. Ex: Asia/Kolkata
   * @returns dateTime in ISO format ex: Tue Jan 30 2024 16:37:37 GMT+0530 (India Standard Time)
   */
    static convertUTCtoISO(utcDateTime: string, timeZone: string): Date {
        const dateTime = utcToZonedTime(utcDateTime, timeZone)
        return dateTime
    }

    static getCurrentDateTimeWithoutMilliSeconds(): string {
        const currenDateTimeObject = new Date(OrganizationDate.currentDateToISOString());
        currenDateTimeObject.setMilliseconds(0);
        return currenDateTimeObject.toISOString();
    }
}