import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CFSDataTypes } from "projects/den-core/contants";
import { EntitySchemaFieldContainer } from "projects/den-core/schema-models";
import { FormRenderService } from "../shared/service/form-render-service";


export class FormValidator {
  constructor(private formRenderService: FormRenderService) {
  }

  uniqueValidation(schemaField: EntitySchemaFieldContainer): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } => {
      const controlValue = this.validateAndReturnString(control?.value);
      const schemaFieldContainer: EntitySchemaFieldContainer = schemaField;

      const uniqueValuesList: string[] = this.checkForDuplicateValue(schemaFieldContainer);
      return uniqueValuesList && uniqueValuesList.indexOf(control.value ? controlValue.toLowerCase() : control?.value?.toLowerCase()) === -1 ? null : { 'uniqueValidation': control?.value };

    }
  };

  private validateAndReturnString(value: string | any) {
    if (Array.isArray(value)) {
      return value;
    } else if (isNaN(value)) {
      return value?.trim();
    } else {
      return value;
    }
  }

  checkForDuplicateValue(s: EntitySchemaFieldContainer & { distinctValues?: string[] }) {
    const distinctValues = this.converToLowerCaseForStringType(s)
    const formData = this.formRenderService.getData();
    if (formData) {
      // const uniqueRecordValue = isNaN(formData[s.fieldName]) ? formData[s.fieldName]?.toLowerCase().trim() : formData[s.fieldName];
      const uniqueRecordValue = this.validateAndReturnString(formData[s.fieldName])
      return distinctValues.filter(item => item !== uniqueRecordValue);
    }
    else {
      return distinctValues;

    }

  }


  converToLowerCaseForStringType(s: EntitySchemaFieldContainer & { distinctValues?: string[] }) {
    if (s.entitySchemaField.fieldType === CFSDataTypes.NUMBER || s.entitySchemaField?.fieldType === CFSDataTypes.DOUBLE) {
      return s?.distinctValues || [];
    } else {
      return s?.distinctValues ? s?.distinctValues.map(item => item.toLowerCase().trim()) : [];
    }
  }

}
