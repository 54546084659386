import { PageBuilderRouterConstants } from "projects/den-core/src/lib/page-builder/public_api";

export class RouterConstant {

    //Administration
    static APP = 'app';
    static MAIN = 'main';
    static LANDING_PAGE = 'landing-page';
    static DEV_DASHBOARD = 'dev-dashboard';
    static APP_MANAGEMENT = 'app-management';
    static USER_MANAGEMENT = 'user-management';
    static APP_DEVELOPER_USERS = 'app-developer-users';
    static SITES = 'sites';
    static USER_POOLS = 'user-pools';
    static USER_POOL_SETTINGS = 'user-pool-settings';
    static USER_ROLES = 'user-roles';
    static GLOBAL_API_CLIENT = 'global-client-client';
    static WORKFLOW_MANAGER = 'workflow-manager';
    static APP_AUDIT_TRAIL = 'app-audit-trail';
    static SYSTEM_DATA = 'system-data';
    static APP_USERS = 'app-users';
    static DEV_APP_USERS = 'dev-app-users';
    static CONFIGURATION = 'configuration';
    static LDAP_SETTINGS = 'ldap-settings';

    //System data menu items
    static DEVICE_REGISTRATION = 'device-registration';
    static LOCATION_TYPE = 'location-type';
    static LOCATION = 'location';
    static HYBRID_USER = 'hybrid-user';

    static SITES_LOCATIONS = 'sites-locations';

    //App development
    static DOMAIN_MODELS = 'data-models';
    static PAGE_BUILDER = PageBuilderRouterConstants.PAGE_BUILDER;
    static DOMAIN_SERVICES = 'domain-services';
    static WORK_FLOWS = 'work-flows';
    static M_VIEWS = 'mviews';
    static THREE_D_ENTITIES = '3d-entities';
    static ICONS = 'icons';
    static DEVELOPMENT_AUDIT_TRAIL = 'development-audit-trail';
    static AI_PLAYGROUND = 'ai-playground';
    static REPORT_SCHEDULER ='report-scheduler'
    // static APP_SITE_MAP = 'app-site-map';

    //User tasks
    static FORMS = 'forms';
    static PAGES = PageBuilderRouterConstants.PAGES;
    static REPORTS = 'reports';


    static UN_AUTHORIZE_VIEW = 'unAuthorizeView';
    static FS_CONSOLE = 'fs-console';
    static PROCESS_MODELLER = 'process-modeller';
    static ADMINISTRATION = 'administration';
    static USER_ACCOUNT = 'user-account';
    static ROLES = 'roles';
    static PAGE_BUILDER_PREVIEW = PageBuilderRouterConstants.PAGE_BUILDER_PREVIEW;
    static LIST = 'list';
    static PROCESS_MANAGER = 'process-manager';
    static CONTINUOUS_PROCESS = 'continuous-process';
    static USER_TASK = 'user-task';
    static RESOURCE_LIBRARY = 'resource-library';
    static MICROSERVICES = 'microservices';
    static PAGEBUILDER = 'page-builder'
    static SUPPORT = 'support'
    static ABOUT = 'about'
    static ADMIN_DASHBOARD = 'admin-dashboard'
    static DEVELOPER_DASHBOARD = 'developer-dashboard'
    static PROFILE = 'profile'
    static GLOBAL_SETTINGS = 'global-settings'
    static APP_STUDIO = 'app-studio';
    static SMTP_SETTING = 'smtp-settings';
}
