import { isValid, parse } from "date-fns";
import { DateTimeHelper, IdentityGenerator } from "projects/den-core";
import { DsResultValue } from "projects/den-core/page-builder";
import { BaseChart, BaseChartSeries, BubbleChart, BubbleChartSeries, ChartDateXAxisFormat, ChartDsResult } from "projects/den-core/src/lib/models/chart/chart-models";

export class ChartDataConstructionHelper {
    /**Combo series format construction Eg: bar-line chart*/
    static constructComboChartGroupData(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let chartData: BaseChart[] = [];
        if (chartDsRes?.length === 0) {
            return chartData;
        }
        yAxisPropertyList.forEach(ya => {
            let series: BaseChartSeries[] = [];
            const propName = this.getPropName(ya);
            series = this.constructComboChartSeries(chartDsRes, ya, series, xAxisProperty, xAxisDateFormat);
            chartData.push(new BaseChart(propName, series));
        })
        return chartData;
    }

    static constructStackedComboChartGroupData(chartDsRes: any[], xAxisProperty: string, yAxisProperty: string, groupingProperty: string, _xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let chartData: BaseChart[] = [];
        let filteredChart = chartDsRes.reduce((result, chartData) => {
            result[chartData[xAxisProperty]] = result[chartData[xAxisProperty]] || [];
            result[chartData[xAxisProperty]].push(chartData);
            return result;

        }, {});

        Object.keys(filteredChart)
            .forEach((lengthKey) => {
                let seriesArray = filteredChart[lengthKey];
                let series: BaseChartSeries[] = [];
                series = this.constructStackedVerticalSeries(seriesArray, groupingProperty, yAxisProperty, series);
                chartData.push(new BaseChart(lengthKey, series));
            });

        return chartData;
    }

    private static constructStackedVerticalSeries(filteredList: any[], groupingProperty: string, yAxisProperty: string, series: BaseChartSeries[]): BaseChartSeries[] {
        const key = groupingProperty;
        series = filteredList.map((result) => {
            return { name: this.getSeriesValue(result, key), value: this.getSeriesValue(result, yAxisProperty) }
        });
        return series;
    }

    static constructStackedComboChartLineData(chartDsRes: ChartDsResult[], xAxisProperty: string, yAxisLineProperty: string, _xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let chartData: BaseChart[] = [];
        let series: BaseChartSeries[] = [];
        let filteredChart = chartDsRes.reduce((result, chartData) => {
            result[chartData[xAxisProperty]] = result[chartData[xAxisProperty]] || [];
            result[chartData[xAxisProperty]].push(chartData);
            return result;

        }, {});
        Object.keys(filteredChart)
            .forEach((lengthKey) => {
                let seriesArray = filteredChart[lengthKey];
                let seriesValue = this.constructStackedLineChartSeries(seriesArray, yAxisLineProperty, lengthKey);
                series.push(seriesValue);
            });
        chartData.push(new BaseChart('Total', series));
        return chartData;
    }

    private static constructStackedLineChartSeries(filteredList: any[], yAxisProperty: string, lengthKey: string): BaseChartSeries {
        let totalValue;
        if (filteredList.some((result) => typeof result[yAxisProperty] === 'string')) {
            return { name: lengthKey, value: 0 };
        }
        totalValue = filteredList.reduce((total, filteredValue) => {
            return total + this.getSeriesValue(filteredValue, yAxisProperty);
        }, 0);
        return { name: lengthKey, value: totalValue };
    }

    private static constructComboChartSeries(chartDsRes: DsResultValue[], ya: string, series: BaseChartSeries[], xAxisProperty: string, xAxisDateFormat: ChartDateXAxisFormat) {
        let value;
        // const dsList = this.getDSAsListByDsName(ya, chartDsRes);
        // dsList.forEach(dsRes => {
        const yaPropName = this.getPropName(ya);
        const xaPropName = this.getPropName(xAxisProperty);
        const seriesName = this.formatSeriesName(chartDsRes, xaPropName, xAxisDateFormat);
        value = this.getSeriesValue(chartDsRes, yaPropName);
        series.push(new BaseChartSeries(seriesName, value));
        // })
        return series;
    }
    // private static getDSAsListByDsName(property: string, chartDsRes: DsResultValue[]) {
    //     const dsName = property.includes('.') && property.split('.').length ? property.split('.')[0] : chartDsRes[0].dsName;
    //     const dsRes = chartDsRes.find(res => res['dsName'] === dsName);
    //     const ds = dsRes && dsRes.dsList ? dsRes.dsList : [];
    //     return ds;
    // }

    /**single series format construction Eg: gauge-chart,pie-chart*/
    static constructGroupStackedBarChartData(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let chartData: BaseChart[] = [];
        // const arrSize = this.getDSListSize(chartDsRes);
        // arrSize.forEach((_, index) => {
        let series: BaseChartSeries[] = [];
        // const ds = this.getDatasourceByName(xAxisProperty, chartDsRes, index);
        const propName = this.getPropName(xAxisProperty);
        const seriesName = this.formatSeriesName(chartDsRes, propName, xAxisDateFormat);
        series = this.constructGroupSeries(chartDsRes, yAxisPropertyList, series);
        chartData.push(new BaseChart(seriesName, series));
        // })
        return chartData;
    }

    static constructGroupChartData(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let chartData: BaseChart[] = [];
        const xAxisPropertyValue = JSON.parse(ChartDataConstructionHelper.getValue(chartDsRes, xAxisProperty));
        const name = JSON.parse(ChartDataConstructionHelper.getValue(chartDsRes, yAxisPropertyList[0]));
        if (Array.isArray(xAxisPropertyValue)) {
            const series = this.constructLineAreaChartSeries(chartDsRes, xAxisProperty, yAxisPropertyList, xAxisDateFormat, xAxisPropertyValue);
            if (yAxisPropertyList.length) {
                chartData.push(new BaseChart(name[0], series));
            }
        }
        return chartData;
    }

    private static constructLineAreaChartSeries(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[],
        xAxisDateFormat: ChartDateXAxisFormat, xAxisValue: any[]): BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        let yValueList;
        if (yAxisPropertyList.length === 1) {
            yValueList = ChartDataConstructionHelper.getValue(chartDsRes, yAxisPropertyList[0]);
        } else if (yAxisPropertyList.length > 1) {
            yValueList = ChartDataConstructionHelper.getValue(chartDsRes, yAxisPropertyList[1]);
        }

        if (typeof yValueList[0] === 'string') {
            return series;
        }
        yValueList.forEach((element, index) => {
            let name = element;
            if (this.canFormatXAxisValue(xAxisDateFormat, element)) {
                name = ChartDataConstructionHelper.formatSeriesName(element, xAxisProperty, xAxisDateFormat);
            }
            const value = xAxisValue[index].toString();
            series.push(new BaseChartSeries(value, name));
        });
        return series;
    }

    private static constructGroupSeries(chartDsRes: DsResultValue[], yAxisPropertyList: string[], series: BaseChartSeries[]) {
        let value;
        yAxisPropertyList.forEach(ya => {
            const propName = this.getPropName(ya);
            // const ds = this.getDatasourceByName(ya, chartDsRes, index);
            value = this.getSeriesValue(chartDsRes, propName);
            series.push(new BaseChartSeries(propName, value));
        })
        return series;
    }
    /**chart with name and series format construction Eg: line-chart,area-chart*/
    static constructChartSeries(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        // const arrSize = this.getDSListSize(chartDsRes);
        yAxisPropertyList.forEach(ya => {
            // const yAxisProp = this.getPropName(ya);
            // arrSize.forEach((_, index) => {
            // const xAxisProp = this.getPropName(xAxisProperty);
            // const ds = this.getDatasourceByName(ya, chartDsRes, index);
            const seriesName = this.formatSeriesName(chartDsRes, xAxisProperty, xAxisDateFormat);
            const value = this.getSeriesValue(chartDsRes, ya);
            series.push(new BaseChartSeries(seriesName, value));
            // })
        });
        return series;
    }

    /**chart with name and series format construction for pie-chart*/
    static constructPieChartSeries(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[]): BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        // const arrSize = this.getDSListSize(chartDsRes);
        // arrSize.forEach((_, index) => {
        if (yAxisPropertyList.length <= 2) {
            let seriesName: any, value: any;
            yAxisPropertyList.forEach(ya => {
                const yAxisProp = this.getPropName(ya);
                // const ds = this.getDatasourceByName(ya, chartDsRes, index);
                value = this.getValue(chartDsRes, yAxisProp) ? this.getValue(chartDsRes, yAxisProp) : 0;
            })
            seriesName = this.getSeriesName(chartDsRes, xAxisProperty);
            series.push(new BaseChartSeries(seriesName, value));
        }
        // });
        return series;
    }

    // private static getDatasourceByName(property: string, chartDsRes: DsResultValue[], index: number): any {
    //     const dsName = property.includes('.') && property.split('.').length ? property.split('.')[0] : chartDsRes[0].dsName;
    //     const dsRes = chartDsRes.find(res => res['dsName'] === dsName);
    //     const ds = dsRes && dsRes.dsList ? dsRes.dsList[index] : null;
    //     return ds;
    // }
    private static getPropName(property: string): string {
        const propName = property.includes('.') && property.split('.').length ? property.split('.')[1] : property;
        return propName;
    }

    static formatSeriesName(dsRes: DsResultValue[], propName: string, xAxisDateFormat: ChartDateXAxisFormat): string {
        let seriesName = this.getSeriesName(dsRes, propName);
        if (this.canFormatXAxisValue(xAxisDateFormat, seriesName)) {
            return this.constructDateFormat(seriesName, xAxisDateFormat);
        }
        return seriesName;
    }
    private static canFormatXAxisValue(xAxisDateFormat: ChartDateXAxisFormat, seriesName: string): boolean {
        return xAxisDateFormat && seriesName != 'NA' && this.isValidDate(seriesName, xAxisDateFormat);
    }
    private static constructDateFormat(seriesName: string, xAxisDateFormat: ChartDateXAxisFormat): string {
        return DateTimeHelper.getFormattedDateString(xAxisDateFormat.outputFormat, parse(seriesName, xAxisDateFormat.inputFormat, DateTimeHelper.getCurrentDateInOrgTimezone()));
    }
    private static isValidDate(seriesName: string, xAxisDateFormat: ChartDateXAxisFormat): boolean {
        return isValid(parse(seriesName, xAxisDateFormat.inputFormat, new Date()));
    }

    private static getSeriesName(dsRes: DsResultValue[], propName: string): string {
        return dsRes ? (this.hasValue(dsRes, propName) ? this.getValue(dsRes, propName) : 'NA') : 'NA';
    }
    private static hasValue(dsRes: DsResultValue[], propName: string) {
        // return typeof dsRes[propName] === 'number' || typeof dsRes[propName] === 'string' || typeof dsRes[propName] === 'boolean'
        const result = dsRes.find(res => res.fieldName === propName)?.value;
        return typeof result === 'number' || typeof result === 'string' || typeof result === 'boolean'
    }

    public static getValue(dsRes: DsResultValue[], propName: string) {
        const dsResultValue = dsRes.find(res => res.fieldName === propName);
        return dsResultValue?.referenceData.isDefined ? dsResultValue.referenceData.get : dsResultValue?.value;
    }

    // private static getDSListSize(chartDsRes: DsResultValue[]): number[] {
    //     const maxCount = chartDsRes.map(dsRes => dsRes.dsList.length).sort((a, b) => b - a)[0] || 0;
    //     const arrSize = [];
    //     for (let step = 0; step < maxCount; step++) {
    //         arrSize.push(step);
    //     }
    //     return arrSize;
    // }
    public static getSeriesValue(ds: DsResultValue[], propName: any) {
        const propVal = this.getValue(ds, propName) ? this.getValue(ds, propName) : 0;
        let value = propVal;
        if (typeof propVal === 'string') {
            value = this.isInt(propVal) ? parseInt(propVal) : this.isFloat(propVal) ? parseFloat(propVal) : 0;
        }
        return value;
    }

    public static getTreeMapParentValue(ds: DsResultValue[], propName: any) {
        if(propName) {
            const propVal = this.getValue(ds, propName) ? this.getValue(ds, propName) : 0;
            return propVal;
        }
    }
    
    private static isInt(val: string) {
        const n = parseInt(val)
        return Number(n) === n && n % 1 === 0;
    }

    private static isFloat(val: string) {
        const n = parseInt(val)
        return Number(n) === n && n % 1 !== 0;
    }

    public static constructStaticChartData(chartDataSource: any, layerPadding: number, layerHeight: number,
        xAxisProperty: string) {
        let chartData: BaseChart[] = [];
        chartDataSource.forEach((value, index) => {
            const propertyValue = this.getValue(value, xAxisProperty) ? this.getValue(value, xAxisProperty) : IdentityGenerator.guid();
            if (index === 0) {
                let series: BaseChartSeries[] = this.constructStaticSeriesInitialData(layerHeight);
                chartData.push(new BaseChart(propertyValue, series));
            } else {
                let series: BaseChartSeries[] = this.constructStaticSeriesData(layerPadding);
                chartData.push(new BaseChart(propertyValue, series));
            }
        });
        return chartData;
    }

    private static constructStaticSeriesData(padding: number) {
        let yAxisPropertyList = this.getStaticYaxisPropertyList();
        let series: BaseChartSeries[] = [];
        yAxisPropertyList.forEach(property => {
            series.push(new BaseChartSeries(property, padding));
        })
        return series;
    }

    private static constructStaticSeriesInitialData(initialHeight: number) {
        let yAxisPropertyList = this.getStaticYaxisPropertyList();
        let series: BaseChartSeries[] = [];
        yAxisPropertyList.forEach((property, index) => {
            if (index === 1) {
                series.push(new BaseChartSeries(property, initialHeight));
            } else {
                series.push(new BaseChartSeries(property, 0));
            }
        })
        return series;
    }

    private static getStaticYaxisPropertyList() {
        return ['property1', 'property2', 'property3'];
    }

    static constructBubbleChartData(chartDsRes: DsResultValue[], xAxisProperty: string, yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BubbleChart[] {
        if (yAxisPropertyList.length !== 3) {
            return null;
        }
        let chartData: BubbleChart[] = [];
        // chartDsRes.forEach((_, index) => {
        let series: BubbleChartSeries[] = [];
        // const ds = this.getDatasourceByName(xAxisProperty, chartDsRes, index);
        // const propName = this.getPropName(xAxisProperty);
        const seriesName = this.formatSeriesName(chartDsRes, xAxisProperty, xAxisDateFormat);
        series = this.constructBubbleChartSeries(chartDsRes, yAxisPropertyList, series);
        chartData.push(new BubbleChart(seriesName, series));
        // })
        return chartData;
    }

    private static constructBubbleChartSeries(chartDsRes: DsResultValue[], yAxisPropertyList: string[], series: BubbleChartSeries[]) {
        let bubbleChartSeries = new BubbleChartSeries();

        const xValue = this.getSeriesValue(chartDsRes, yAxisPropertyList[0]);
        bubbleChartSeries.x = String(xValue);
        bubbleChartSeries.name = bubbleChartSeries.x;

        const yValue = this.getSeriesValue(chartDsRes, yAxisPropertyList[1]);
        bubbleChartSeries.y = yValue;

        const rValue = this.getSeriesValue(chartDsRes, yAxisPropertyList[2]);
        bubbleChartSeries.r = rValue;

        series.push(bubbleChartSeries);
        return series;
    }

    static getStackedBarLineChartLegendValues(dsList: any[], groupProperty: string) {
        return [...new Set(dsList.map(ds => ds[groupProperty] || 'NA'))];
    }

    static getGroupBarChartLegendValues(dsList: DsResultValue[][], yAxisProperty: string) {
        return [...new Set(dsList.map(ds => ds.find(value => value.fieldName === yAxisProperty).value || 'NA'))];
    }

}
