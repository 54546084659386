import { DsResult } from '../../../../data-source/data-source-result-entities';
import { ControlConditionGroup, ControlConditionType, ControlCondition, ControlConditionGroupEvaluationResult } from '../models/control-conditions';
import { Injectable } from '@angular/core';
import { FilterOperator } from '../../../../../models/base.models';
import { RtSome } from '../../../../../../lib/utils/option-helper';
import { CategoryEnum, LogService } from 'projects/den-core';
import { LogData } from 'projects/den-core/src/lib/log-service/log.service';
import { PageService } from 'projects/den-core/page-builder';

@Injectable()
export class ControlConditionEvaluatorService {
  constructor(private logService: LogService, private pageService: PageService) {

  }
  evaluateConditions(dsResult: DsResult, controlConditionGroups: ControlConditionGroup[], instanceId: string): ControlConditionGroupEvaluationResult[] {
    let controlConditionResults: ControlConditionGroupEvaluationResult[] = [];
    if (dsResult instanceof DsResult) {
      controlConditionGroups.map((controlConditionGroup) => {
        const controlConditionResult = this.evaluateControlConditionGroup(controlConditionGroup, dsResult);
        if (controlConditionResult != undefined)
        controlConditionResults.push(controlConditionResult);
    })
    } else if (controlConditionGroups?.length > 0) {
      this.logService.error('Control Condition cannot be added to list data/control', null, CategoryEnum.PageBuilder, new LogData(instanceId, this.pageService.pageName));
    }
    return controlConditionResults;
  }

  private evaluateControlConditionGroup(controlConditionGroup: ControlConditionGroup, dsResult: DsResult): ControlConditionGroupEvaluationResult {
    const evaluationResults: ConditionEvaluationResult[] = controlConditionGroup.controlConditions.map(controlCondition => {
      const propertyValue = dsResult.data.find(dsResVal => dsResVal.fieldName.toLowerCase() === controlCondition.dsPropertyName.toLowerCase())?.value;
      const evaluationResult = this.evaluateCondition(controlCondition, propertyValue, controlCondition.comparingValue);
      return evaluationResult;
    });
    const canExecuteBehaviour = this.canExecuteBehaviour(controlConditionGroup, evaluationResults);
    const groupEvaluationResults: ControlConditionGroupEvaluationResult = { controlConditionGroup: controlConditionGroup, canExecuteBehaviour: canExecuteBehaviour };
    return groupEvaluationResults;
  }

  private canExecuteBehaviour(controlConditionGroup: ControlConditionGroup, evaluationResults: ConditionEvaluationResult[]) {
    if (evaluationResults.length == 0) return true;

    if (controlConditionGroup.conditionType == ControlConditionType.ALL) {
      return evaluationResults.every(result => result.conditionMatched);
    } else {
      return evaluationResults.some(result => result.conditionMatched);
    }
  }

  private evaluateCondition(controlCondition: ControlCondition, propertyValue: string, comparingValue: string): ConditionEvaluationResult {
    if (propertyValue === undefined) {
      return { conditionMatched: false };
    }

    if (controlCondition.isExpression) {
      const expression = "const evalFunc=" + controlCondition.expressionValue + "evalFunc('" + propertyValue + "')";
      const result = eval(expression);
      return { conditionMatched: result };
    } else {
      switch (controlCondition.condition) {
        case FilterOperator.LESS_THAN:
          return { conditionMatched: propertyValue < comparingValue };
        case FilterOperator.GREATER_THAN:
          return { conditionMatched: propertyValue > comparingValue };
        case FilterOperator.GREATER_THAN_EQUALS:
          return { conditionMatched: propertyValue >= comparingValue };
        case FilterOperator.LESS_THAN_EQUALS:
          return { conditionMatched: propertyValue <= comparingValue };
        case FilterOperator.EQUALS:
          return { conditionMatched: propertyValue.toString() == comparingValue };
        case FilterOperator.NOT_EQUAL:
          return { conditionMatched: propertyValue.toString() != comparingValue };
        case FilterOperator.IN:
          try {
            const value = JSON.parse(comparingValue);
            return { conditionMatched: value?.includes(propertyValue) };
          } catch {
            return { conditionMatched: false };
          }
        case FilterOperator.IS_NULL:
          return { conditionMatched: propertyValue == null };
        case FilterOperator.IS_NOT_NULL:
          return { conditionMatched: propertyValue !== null };
        // case Operators.IS_SAME:
        //   return { conditionMatched: isEqual(new Date(propertyValue), new Date(comparingValue)) }
        // case Operators.IS_BEFORE:
        //   return { conditionMatched: isBefore(new Date(propertyValue), new Date(comparingValue)) };
        // case Operators.IS_AFTER:
        //   return { conditionMatched: isAfter(new Date(propertyValue), new Date(comparingValue)) };
        default: return { conditionMatched: false };
      }
    }
  }
}

type ConditionEvaluationResult = { conditionMatched: boolean }
