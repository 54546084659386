import { ChartColorBindingHelper } from './shared/helpers/chart-color-binding-helper';
import { ChartHelper, RtOption } from "projects/den-core";
import { DsResult, DsResultArray, SimpleAngularControlInfo, UsableAttributeValue } from "projects/den-core/src/lib/page-builder/public_api";
import { Observable } from "rxjs";
import { BaseChart, ChartAttributesEnum, ChartDsResult, ChartTypes } from 'projects/den-core/src/lib/models/chart/chart-models';
import { AngularDefaultControlRenderService } from 'projects/den-core/src/lib/page-builder/services/render/strategy/angular-default-control-render-service';
import { SimpleAngularControl, SimpleAngularListControl } from 'projects/den-core/page-builder/core';
import { BaseChartSeries } from 'projects/den-core/src/lib/models/chart/chart-models';
import { ScaleType } from '@swimlane/ngx-charts';
import { Directive, ElementRef, Injector } from '@angular/core';
import { WebControlRegistryConstant } from 'projects/den-core/page-builder';
import { ResizedEvent } from 'projects/den-core/directive';
import { CoreChartAttributesConstants, VerticalBarChartEnum } from './shared/constants/core-chart-attribute-constant';

@Directive()
export class AbstractChartDsResult extends SimpleAngularControl {
  dsChartResult: ChartDsResult[] = [];
  chartTypes = ChartTypes;
  chartData: BaseChartSeries[] = new Array<BaseChartSeries>();
  multiSeries: BaseChart[] = new Array<BaseChart>();
  colorCodes = { name: 'chartColorTheme', selectable: true, group: ScaleType.Ordinal, domain: [] };
  lineColorTheme = { name: 'lineColorTheme', selectable: true, group: ScaleType.Ordinal, domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'] };
  dimensions: number[];
  dimensionsArray: [number, number];
  BINDING_PROPS = ChartAttributesEnum;
  CONFIG_BINDINGS = {};
  xAxisProperty: string;
  yAxisPropertyList: string[] = [];
  legendValues: string[] = [];
  legendColors: string[] = [];
  legendColorScheme = { name: 'legendColorScheme', selectable: true, group: ScaleType.Ordinal, domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'] };
  colors: string[] = [];
  isGroupedChartType: boolean;
  barPadding: number;
  isArrayResponse = false;
  xyColor = this.CONFIG_BINDINGS[this.BINDING_PROPS.X_Y_AXIS_COLOR];
  scrollBarConstants = [WebControlRegistryConstant.BAR_CHARTS_LIST, WebControlRegistryConstant.GROUPED_BAR_CHARTS, WebControlRegistryConstant.LINE_AREA_CHARTS_LIST, WebControlRegistryConstant.LINE_AREA_CHARTS, WebControlRegistryConstant.STACKED_VERTICAL_BAR_LINE_CHART, WebControlRegistryConstant.BAR_LINE_CHART]
  public parentElementRef: ElementRef;
  // axisTickFormatting: Function = (val: any) => this.axisTickFormattingFn(val);
  constructor(injector: Injector) {
    super(injector);
    this.parentElementRef = injector.get(ElementRef);
  }
  onDatasourceResolved(data: RtOption<DsResult>): Observable<SimpleAngularControlInfo> {
    if (data.isDefined) {
      this.dataSource = data.get.data;
      ChartHelper.updateGridColumnsDefinitionValue(this);
      ChartHelper.updateGridWithDataSource(this);
    }
    return this.getControlInfo();
    this.xyColor = this.CONFIG_BINDINGS[this.BINDING_PROPS.X_Y_AXIS_COLOR];

  }

  applyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    super.applyConfigurationAttributes(configurationAttributeValues);
    this.updateWidthAndHeight(configurationAttributeValues);
    ChartColorBindingHelper.applyDefaultColorConfigurationAttributes(this);
  }

  onResized(_resizeEvent: ResizedEvent) {
    if (this.CONFIG_BINDINGS && this.CONFIG_BINDINGS[this.BINDING_PROPS.FIT_CONTAINER]) {
      const parentWidth = this.parentElementRef.nativeElement.getBoundingClientRect()?.width;
      const parentHeight = this.parentElementRef.nativeElement.getBoundingClientRect()?.height;
      if (parentWidth > 0 || parentHeight > 0) {
        const bufferValue = this.CONFIG_BINDINGS[this.BINDING_PROPS.BUFFER_SPACE] ? this.CONFIG_BINDINGS[this.BINDING_PROPS.BUFFER_SPACE] : 50;
        this.getUpdatedDimensions(bufferValue, parentWidth, parentHeight)
      }
    }
  }

  private updateWidthAndHeight(configurationAttributeValues: UsableAttributeValue<unknown>[]) {
    const { widthAttribute, heightAttribute, fitAttribute, bufferSpace } = this.extractWidthAndHeight(configurationAttributeValues);
    if (widthAttribute || heightAttribute || fitAttribute) {
      this.dimensionsArray = undefined;
      if (fitAttribute?.value) {
        this.parentElementRef.nativeElement.style.width = '100%'
        this.parentElementRef.nativeElement.style.height = '100%'
        const parentWidth = this.parentElementRef.nativeElement.getBoundingClientRect()?.width;
        const parentHeight = this.parentElementRef.nativeElement.getBoundingClientRect()?.height;
        if (parentWidth > 0 || parentHeight > 0) {
          const bufferValue = bufferSpace?.value ? bufferSpace?.value : 50;
          this.getUpdatedDimensions(bufferValue as number, parentWidth, parentHeight);
        } else {
          this.dimensionsArray = [widthAttribute.value as number, heightAttribute.value as number];
          this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = widthAttribute.value;
          this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = heightAttribute.value;
        }
      }
      else {
        this.dimensionsArray = this.scrollBarConstants.includes(this.controlInstance.controlName) ? [undefined, heightAttribute.value as number] : [widthAttribute.value as number, heightAttribute.value as number];
        this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = widthAttribute.value as number;
        this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = heightAttribute.value as number;
      }
    }
  }


  public getUpdatedDimensions(bufferValue: number, parentWidth: number, parentHeight: number) {
    const parentContainerWidth = parentWidth - bufferValue;
    const parentContainerHeight = parentHeight - bufferValue;
    const updatedWidth = parseInt(parentContainerWidth.toString(), 10);
    const updatedHeight = parseInt(parentContainerHeight.toString(), 10);
    this.dimensionsArray = [updatedWidth, updatedHeight];
    this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = updatedWidth;
    this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = updatedHeight;
  }

  private extractWidthAndHeight(configurationAttributeValues: UsableAttributeValue<unknown>[]) {
    const widthAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.WIDTH);
    const heightAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.HEIGHT);
    const fitAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.FIT_CONTAINER);
    const bufferSpace = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.BUFFER_SPACE);
    return { widthAttribute, heightAttribute, fitAttribute, bufferSpace };
  }

  addPositionStyle(): string {
    if (this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[0].name || this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[1].name) {
      return 'rt-flex-column';
    } else return 'row';
  }

  returnStyleOrder(isChart: boolean) {
    let order: number;
    if (this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[0].name || this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[2].name) {
      order = isChart ? 2 : 1
    } else { order = isChart ? 1 : 2 }
    return order;
  }
}

@Directive()
export class AbstractChartDsResultArray extends SimpleAngularListControl {
  controlRenderService: AngularDefaultControlRenderService;
  dsChartResult: ChartDsResult[] = [];
  chartTypes = ChartTypes;
  chartData: BaseChartSeries[] = new Array<BaseChartSeries>();
  multiSeries: BaseChart[] = new Array<BaseChart>();
  colorCodes = { name: 'chartColorTheme', selectable: true, group: ScaleType.Ordinal, domain: [] };
  lineColorTheme = { name: 'lineColorTheme', selectable: true, group: ScaleType.Ordinal, domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'] };
  dimensions: number[];
  dimensionsArray: [number, number];
  BINDING_PROPS = ChartAttributesEnum;
  CONFIG_BINDINGS = {};
  xAxisProperty: string;
  yAxisPropertyList: string[] = [];
  legendValues: string[] = [];
  legendColors: string[] = [];
  legendColorScheme = { name: 'legendColorScheme', selectable: true, group: ScaleType.Ordinal, domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'] };
  colors: string[] = [];
  isGroupedChartType: boolean;
  isStaticDataSource: boolean;
  barPadding: number;
  dataSource: any;
  isArrayResponse = true;
  xyColor = this.CONFIG_BINDINGS[this.BINDING_PROPS.X_Y_AXIS_COLOR];
  scrollBarConstants = [WebControlRegistryConstant.BAR_CHARTS_LIST, WebControlRegistryConstant.GROUPED_BAR_CHARTS, WebControlRegistryConstant.LINE_AREA_CHARTS_LIST, WebControlRegistryConstant.LINE_AREA_CHARTS, WebControlRegistryConstant.STACKED_VERTICAL_BAR_LINE_CHART, WebControlRegistryConstant.BAR_LINE_CHART]
  public parentElementRef: ElementRef;
  constructor(injector: Injector) {
    super(injector);
    this.parentElementRef = injector.get(ElementRef);
  }

  onArrayDatasourceResolved(data: RtOption<DsResultArray>, dsName: string): SimpleAngularControlInfo {
    if (data.isDefined) {
      this.dataSource = data.get.toList();
      // this.dsChartResult.push({ dsName: dsName, dsList: data.get.toList() });
      ChartHelper.updateGridColumnsDefinitionValue(this);
      ChartHelper.updateGridWithDataSource(this);
    }
    return this.getControlInfo();
    this.xyColor = this.CONFIG_BINDINGS[this.BINDING_PROPS.X_Y_AXIS_COLOR];
  }


  applyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    super.applyConfigurationAttributes(configurationAttributeValues);
    this.updateWidthAndHeight(configurationAttributeValues);
    ChartColorBindingHelper.applyDefaultColorConfigurationAttributes(this);
  }

  onResized(_resizeEvent: ResizedEvent) {
    if (this.CONFIG_BINDINGS && this.CONFIG_BINDINGS[this.BINDING_PROPS.FIT_CONTAINER]) {
      const parentWidth = this.parentElementRef.nativeElement.getBoundingClientRect()?.width;
      const parentHeight = this.parentElementRef.nativeElement.getBoundingClientRect()?.height;
      if (parentWidth > 0 || parentHeight > 0) {
        const bufferValue = this.CONFIG_BINDINGS[this.BINDING_PROPS.BUFFER_SPACE] ? this.CONFIG_BINDINGS[this.BINDING_PROPS.BUFFER_SPACE] : 50;
        this.getUpdatedDimensions(bufferValue, parentWidth, parentHeight)
      }
    }
  }

  private updateWidthAndHeight(configurationAttributeValues: UsableAttributeValue<unknown>[]) {
    const { widthAttribute, heightAttribute, fitAttribute, bufferSpace } = this.extractWidthAndHeight(configurationAttributeValues);
    if (widthAttribute || heightAttribute || fitAttribute) {
      this.dimensionsArray = undefined;
      if (fitAttribute?.value) {
        this.parentElementRef.nativeElement.style.width = '100%'
        this.parentElementRef.nativeElement.style.height = '100%'
        const parentWidth = this.parentElementRef.nativeElement.getBoundingClientRect()?.width;
        const parentHeight = this.parentElementRef.nativeElement.getBoundingClientRect()?.height;
        if (parentWidth > 0 || parentHeight > 0) {
          const bufferValue = bufferSpace?.value ? bufferSpace?.value : 50;
          this.getUpdatedDimensions(bufferValue as number, parentWidth, parentHeight);
        } else {
          this.dimensionsArray = [widthAttribute.value as number, heightAttribute.value as number];
          this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = widthAttribute.value;
          this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = heightAttribute.value;
        }
      }
      else {
        this.dimensionsArray = this.scrollBarConstants.includes(this.controlInstance.controlName) ? [undefined, heightAttribute.value as number] : [widthAttribute.value as number, heightAttribute.value as number];
        this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = widthAttribute.value as number;
        this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = heightAttribute.value as number;
        this.parentElementRef.nativeElement.style.removeProperty('width');
        this.parentElementRef.nativeElement.style.removeProperty('height');
      }
    }
  }

  public getUpdatedDimensions(bufferValue: number, parentWidth: number, parentHeight: number) {
    const parentContainerWidth = parentWidth - bufferValue;
    const parentContainerHeight = parentHeight - bufferValue;
    const updatedWidth = parseInt(parentContainerWidth.toString(), 10);
    const updatedHeight = parseInt(parentContainerHeight.toString(), 10);
    this.dimensionsArray = [updatedWidth, updatedHeight];
    this.CONFIG_BINDINGS[this.BINDING_PROPS.WIDTH] = updatedWidth;
    this.CONFIG_BINDINGS[this.BINDING_PROPS.HEIGHT] = updatedHeight;
  }

  private extractWidthAndHeight(configurationAttributeValues: UsableAttributeValue<unknown>[]) {
    const widthAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.WIDTH);
    const heightAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.HEIGHT);
    const fitAttribute = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.FIT_CONTAINER);
    const bufferSpace = configurationAttributeValues.find(configAttribute => configAttribute.name === ChartAttributesEnum.BUFFER_SPACE);
    return { widthAttribute, heightAttribute, fitAttribute, bufferSpace };
  }

  addPositionStyle(): string {
    if (this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[0].name || this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[1].name) {
      return 'rt-flex-column';
    } else return 'row';
  }

  returnStyleOrder(isChart: boolean) {
    let order: number;
    if (this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[0].name || this.CONFIG_BINDINGS[this.BINDING_PROPS.LEGEND_POSITION] == CoreChartAttributesConstants.CHART_LEGEND_POSITION[2].name) {
      order = isChart ? 2 : 1
    } else { order = isChart ? 1 : 2 }
    return order;
  }


}
