import { BaseEnumType } from "projects/den-core/base-models";
import { ChartAttributesEnum } from "projects/den-core/src/lib/models/chart/chart-models";
import { CoreChartAttributesConstants } from "../constants/core-chart-attribute-constant";

export class DefaultAttributeBindingHelper {

    static applyCommonChartAttributes(reference : any){
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.HEIGHT] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.HEIGHT] || 300;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.WIDTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.WIDTH] || 300;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_LEGEND] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_LEGEND] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_LEGEND] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_XAXIS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_XAXIS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_XAXIS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LEFT] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LEFT] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LEFT] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_X_AXIS_LABEL] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_X_AXIS_LABEL] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_X_AXIS_LABEL] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LABEL] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LABEL] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_LABEL] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT_LABEL] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT_LABEL] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_Y_AXIS_RIGHT_LABEL] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.XAXIS_LABEL] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.XAXIS_LABEL] || 'x axis';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.YAXIS_LEFT_LABEL] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.YAXIS_LEFT_LABEL] || 'y axis';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.YAXIS_RIGHT_LABEL] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.YAXIS_RIGHT_LABEL] || 'y axis right';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SCHEME_TYPE] = DefaultAttributeBindingHelper.resolveNameFromObject(reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SCHEME_TYPE]?.type, CoreChartAttributesConstants.SCHEME_TYPE_ATTRIBUTES) || 'ordinal';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_GRID_LINES] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_GRID_LINES] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_GRID_LINES] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ANIMATIONS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ANIMATIONS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ANIMATIONS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LEGEND_TITLE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LEGEND_TITLE] || 'Legend title';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DISABLE_TOOLTIP] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DISABLE_TOOLTIP] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DISABLE_TOOLTIP] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ENABLE_GRADIENT] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ENABLE_GRADIENT] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ENABLE_GRADIENT] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_XAXIS_TICKS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_XAXIS_TICKS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_XAXIS_TICKS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_YAXIS_TICKS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_YAXIS_TICKS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_YAXIS_TICKS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROTATE_XAXIS_TICKS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROTATE_XAXIS_TICKS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROTATE_XAXIS_TICKS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_XAXIS_TICK_LENGTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_XAXIS_TICK_LENGTH] || 16;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_YAXIS_TICK_LENGTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_YAXIS_TICK_LENGTH] || 16;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TIME_LINE] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TIME_LINE] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TIME_LINE] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.AUTO_SCALE] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.AUTO_SCALE] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.AUTO_SCALE] : false;
        return reference;
    }


    private static resolveNameFromObject(id: string, baseEnumTypeArray: Array<BaseEnumType>) {
        let result = baseEnumTypeArray.find((value) => value.name === id);
        if (!result) {
            return null;
        }
        return result.label;
    }
}

export class PieChartDefaultAttributeBindingHelper {

    static applyPieChartAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_GRID_WIDTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_GRID_WIDTH] || 150;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL] || 'Total';
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABELS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABELS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABELS] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_LABELS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_LABELS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TRIM_LABELS] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.EXPLODE_SLICES] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.EXPLODE_SLICES] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.EXPLODE_SLICES] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DOUGHNUT] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DOUGHNUT] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.DOUGHNUT] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_LABEL_LENGTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_LABEL_LENGTH] || 10;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ARC_WIDTH] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ARC_WIDTH] || 0.25;
        return reference;
    }
}

export class GuageChartDefaultAttributeBindingHelper {

    static applyDefaultGaugeAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.UNITS_VALUE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.UNITS_VALUE];
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SMALL_SEGMENTS] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SMALL_SEGMENTS] || 5;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BIG_SEGMENTS] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BIG_SEGMENTS] || 10;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_GAUGE_SCALE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_GAUGE_SCALE] || 1;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_GAUGE_SCALE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_GAUGE_SCALE] || 100;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.START_ANGLE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.START_ANGLE] || -90;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ANGLE_SPAN] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ANGLE_SPAN] || 180;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_AXIS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_AXIS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_AXIS] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_TOTAL] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_TOTAL] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_TOTAL] : true;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_DATA_LABEL] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_DATA_LABEL] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_DATA_LABEL] : false;
        return reference;
    }
}

export class PolarChartDefaultAttributeBindingHelper {

    static applyDefaultPolarChartAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_SERIES_ON_HOVER] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_SERIES_ON_HOVER] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.SHOW_SERIES_ON_HOVER] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_AXIS_MIN_SCALE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_AXIS_MIN_SCALE] || 1;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL_TRIM] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL_TRIM] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL_TRIM] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL_TRIM_SIZE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LABEL_TRIM_SIZE] || 1;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.CURVE_TYPE] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.CURVE_TYPE] || CoreChartAttributesConstants.DEFAULT_CURVE_TYPE;
        return reference;
    }
}

export class BarChartDefaultAttributeBindingHelper {

    static applyDefaultBarChartAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.NO_BAR_WHEN_ZERO_VALUE] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.NO_BAR_WHEN_ZERO_VALUE] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.NO_BAR_WHEN_ZERO_VALUESHOW_DATA_LABEL] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_EDGES] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_EDGES] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_EDGES] : false;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.INNER_PADDING] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.INNER_PADDING] || 15;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_PADDING] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_PADDING] || 8;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.GROUP_PADDING] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.GROUP_PADDING] || 16;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_DOMAINS] = typeof reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_DOMAINS] === 'boolean' ? reference.CONFIG_BINDINGS[reference.BINDING_PROPS.ROUND_DOMAINS] : false;
        return reference;
    }
}

export class BubbleChartDefaultAttributeBindingHelper {

    static applyDefaultBubbleChartAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_RADIUS] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MAX_RADIUS] || 3;
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_RADIUS] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.MIN_RADIUS] || 10;
        return reference;
    }
}

export class ScaleDefaultAttributeBindingHelper {
    static applyDefaultScaleAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_SCALE_MIN] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_SCALE_MIN];
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_SCALE_MAX] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.Y_SCALE_MAX];
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_SCALE_MIN] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_SCALE_MIN];
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_SCALE_MAX] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.X_SCALE_MAX];
        return reference;
    }
}

export class CombinationChartDefaultAttributeBindingHelper {
    static applyDefaultCombinationChartAttributeValues(reference: any) {
        reference.CONFIG_BINDINGS[reference.BINDING_PROPS.RANGE_FILL_OPACITY] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.RANGE_FILL_OPACITY] || 0.15;
        return reference;
    }
}

export class NumberCardChartDefaultAttributeBindingHelper {

    static applyDefaultNumberCardChartAttributeValues(reference: any){
        reference[ChartAttributesEnum.CARD_COLOR] =  reference.CONFIG_BINDINGS[reference.BINDING_PROPS.CARD_COLOR] || '#00cdff';
        reference[ChartAttributesEnum.EMPTY_COLOR] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.EMPTY_COLOR] || '#000000';
        reference[ChartAttributesEnum.TEXT_COLOR] = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.TEXT_COLOR] || '#ffffff';
        return reference;
    }
}
